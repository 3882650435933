import { Chip, Input } from "@nextui-org/react";
import {
  ChangeEvent,
  KeyboardEvent,
  memo,
  ReactNode,
  useCallback,
  useState,
} from "react";

type ChipsInputProps = {
  type?: string;
  isInvalid: boolean;
  errorMessage?: string;
  label: ReactNode;
  placeholder?: string;
  chipColor?:
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "default"
    | "danger"
    | undefined;
  values: string[];
  onChange: (values: string[]) => void;
  validate?: (value: string) => boolean;
};

export const ChipsInput = memo(
  ({
    type,
    label,
    isInvalid,
    chipColor,
    errorMessage,
    placeholder,
    values,
    onChange,
    validate,
  }: ChipsInputProps) => {
    const [inputValue, setInputValue] = useState<string>("");

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (e.key === "Enter" && target.value.trim() !== "") {
          e.preventDefault();
          if (!validate || validate(target.value)) {
            onChange([...values, target.value]);
            setInputValue("");
          }
        }
        if (e.key === "," && target.value.trim() !== "") {
          e.preventDefault();
          if (!validate || validate(target.value)) {
            onChange([...values, target.value]);
            setInputValue("");
          }
        }
      },
      [onChange, validate, values],
    );

    const onChipDelete = useCallback(
      (index: number) => {
        onChange(values.filter((_, i) => i !== index));
      },
      [values, onChange],
    );

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    }, []);

    const handleOnBlur = useCallback(() => {
      if (inputValue.trim() !== "" && (!validate || validate(inputValue))) {
        onChange([...values, inputValue]);
        setInputValue("");
      }
    }, [inputValue, validate, onChange, values]);

    return (
      <div className="flex flex-col">
        <Input
          type={type}
          label={label}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          value={inputValue}
          isInvalid={isInvalid}
          classNames={{
            inputWrapper: `rounded-bl-none rounded-br-none shadow-none ${isInvalid ? "bg-danger-50" : ""}`,
          }}
        />
        <div
          className={`flex flex-row gap-2 p-2 rounded-bl-xl rounded-br-xl flex-wrap ${isInvalid ? "bg-danger-50" : "bg-default-100"}`}
        >
          {values.map((chip, index) => (
            <Chip
              color={chipColor}
              onClose={() => onChipDelete(index)}
              key={index}
            >
              {chip}
            </Chip>
          ))}
        </div>
        {isInvalid && <p className="text-danger text-xs">{errorMessage}</p>}
      </div>
    );
  },
);
