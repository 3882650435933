import { memo, useCallback, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Input } from "@nextui-org/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const passwordSchema = z.object({
  password: z.string().min(8, "Password must be at least 8 characters long"),
});

type SetPasswordInputs = {
  password: string;
};

type SetPasswordProps = {
  onSubmitHandler: (data: { password: string }) => void;
};

export const SetPasswordForm = memo(({ onSubmitHandler }: SetPasswordProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SetPasswordInputs>({
    resolver: zodResolver(passwordSchema),
    mode: "onBlur",
  });

  const isLoading = false;

  const onSubmit: SubmitHandler<SetPasswordInputs> = useCallback(
    (data) => {
      onSubmitHandler(data);
    },
    [onSubmitHandler],
  );

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = useCallback(
    () => setIsVisible(!isVisible),
    [isVisible],
  );

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <Input
        autoFocus
        errorMessage={errors?.password?.message}
        isInvalid={!!errors?.password}
        label="Password"
        endContent={
          <Button
            size="sm"
            variant="light"
            isIconOnly
            onClick={toggleVisibility}
          >
            {isVisible ? (
              <Icon
                className="pointer-events-none text-2xl text-default-400"
                icon="solar:eye-closed-linear"
              />
            ) : (
              <Icon
                className="pointer-events-none text-2xl text-default-400"
                icon="solar:eye-bold"
              />
            )}
          </Button>
        }
        type={isVisible ? "text" : "password"}
        {...register("password")}
      />

      <Button
        size="sm"
        isLoading={isLoading}
        isDisabled={!isValid}
        type="submit"
        color="primary"
      >
        Submit
      </Button>
    </form>
  );
});
