import { Card, CardBody } from "@nextui-org/react";
import { FC, ReactNode, memo } from "react";
import { SectionHeader } from "./SectionHeader";

export type SectionProps = {
  children: ReactNode;
  title: string;
};

export const Section: FC<SectionProps> = memo(({ title, children }) => (
  <section className="flex flex-col grow w-full">
    <SectionHeader title={title} />
    <Card>
      <CardBody className="flex flex-col grow w-full">{children}</CardBody>
    </Card>
  </section>
));
