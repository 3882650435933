// tailwind.config.js
import { nextui } from "@nextui-org/react";

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    "./index.html",
    "./src/**/*.{js,ts,jsx,tsx}",
    "./node_modules/@nextui-org/theme/dist/**/*.{js,ts,jsx,tsx}",
  ],
  darkMode: "selector",
  theme: {
    extend: {},
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
    },
    fontFamily: {
      menlo: ["Menlo", "monospace"],
      ibmMono: ['"IBM Plex Mono"', "monospace"],
    },
  },
  plugins: [
    nextui({
      themes: {
        dark: {
          colors: {
            background: {
              50: "#1C1917",
              100: "#292524",
              200: "#44403C",
              300: "#57534E",
              400: "#78716C",
              500: "#A8A29E",
              600: "#D6D3D1",
              700: "#E7E5E4",
              800: "#F5F5F4",
              900: "#0c0a09",
              DEFAULT: "#0C0A09",
            },
            foreground: {
              50: "#000000",
              100: "#F5F5F4",
              200: "#E7E5E4",
              300: "#D6D3D1",
              400: "#A8A29E",
              500: "#78716C",
              600: "#57534E",
              700: "#44403C",
              800: "#292524",
              900: "#1C1917",
              DEFAULT: "#FAFAF9",
            },
            default: {
              50: "#1C1917",
              100: "#292524",
              200: "#44403C",
              300: "#57534E",
              400: "#78716C",
              500: "#A8A29E",
              600: "#D6D3D1",
              700: "#E7E5E4",
              800: "#F5F5F4",
              900: "#FAFAFA",
              DEFAULT: "#44403C",
            },
            primary: {
              50: "#78350F",
              100: "#92400E",
              200: "#B45309",
              300: "#D97706",
              400: "#F59E0B",
              500: "#FBBF24",
              600: "#FCD34D",
              700: "#FDE68A",
              800: "#FEF3C7",
              900: "#FFFBEB",
              DEFAULT: "#FBBF24",
              foreground: "#292524",
            },
            secondary: {
              50: "#1C1917",
              100: "#292524",
              200: "#44403C",
              300: "#57534E",
              400: "#78716C",
              500: "#A8A29E",
              600: "#D6D3D1",
              700: "#E7E5E4",
              800: "#F5F5F4",
              900: "#FAFAFA",
              DEFAULT: "#E7E5E4",
              foreground: "#292524",
            },
            success: {
              50: "#14532D",
              100: "#166534",
              200: "#15803D",
              300: "#16A34A",
              400: "#22C55E",
              500: "#5EEAD4",
              600: "#93F2B0",
              700: "#BBF7D0",
              800: "#DCFCE7",
              900: "#F0FDF4",
              DEFAULT: "#22C55E",
              foreground: "#E7E5E4",
            },
            warning: {
              50: "#78350F",
              100: "#92400E",
              200: "#B45309",
              300: "#D97706",
              400: "#F59E0B",
              500: "#FBBF24",
              600: "#FCD34D",
              700: "#FDE68A",
              800: "#FEF3C7",
              900: "#FFFBEB",
              DEFAULT: "#F59E0B",
              foreground: "#292524",
            },
            danger: {
              50: "#881337",
              100: "#9F1239",
              200: "#BE123C",
              300: "#E11D48",
              400: "#F43F5E",
              500: "#FB7185",
              600: "#FDA4AF",
              700: "#FECDD3",
              800: "#FFE4E6",
              900: "#FFF1F2",
              DEFAULT: "#FB7185",
              foreground: "#E7E5E4",
            },
          },
        },
        light: {
          colors: {
            background: {
              50: "#fafaf9",
              100: "#f5f5f4",
              200: "#e7e5e4",
              300: "#d6d3d1",
              400: "#a3a3a3",
              500: "#78716c",
              600: "#57534e",
              700: "#44403c",
              800: "#292524",
              900: "#1c1917",
              DEFAULT: "#FFFFFF",
            },
            foreground: {
              50: "#1C1917",
              100: "#292524",
              200: "#44403C",
              300: "#57534E",
              400: "#78716C",
              500: "#A8A29E",
              600: "#D6D3D1",
              700: "#E7E5E4",
              800: "#F5F5F4",
              900: "#FAFAFA",
              DEFAULT: "#0C0A09",
            },
            default: {
              50: "#FAFAF9",
              100: "#F5F5F4",
              200: "#E7E5E4",
              300: "#D6D3D1",
              400: "#A8A29E",
              500: "#78716C",
              600: "#57534E",
              700: "#44403C",
              800: "#292524",
              900: "#1C1917",
              DEFAULT: "#D6D3D1",
              foreground: "#292524",
            },
            primary: {
              50: "#FFFBEB",
              100: "#FEF3C7",
              200: "#FDE68A",
              300: "#FCD34D",
              400: "#FBBF24",
              500: "#F59E0B",
              600: "#D97706",
              700: "#B45309",
              800: "#92400E",
              900: "#78350F",
              DEFAULT: "#FBBF24",
              foreground: "#292524",
            },
            secondary: {
              50: "#FAFAFA",
              100: "#F5F5F4",
              200: "#E7E5E4",
              300: "#D6D3D1",
              400: "#A8A29E",
              500: "#78716C",
              600: "#57534E",
              700: "#44403C",
              800: "#292524",
              900: "#1C1917",
              DEFAULT: "#292524",
              foreground: "#E7E5E4",
            },
            success: {
              50: "#F0FDF4",
              100: "#DCFCE7",
              200: "#BBF7D0",
              300: "#93F2B0",
              400: "#5EEAD4",
              500: "#22C55E",
              600: "#16A34A",
              700: "#15803D",
              800: "#166534",
              900: "#14532D",
              DEFAULT: "#22C55E",
              foreground: "#E7E5E4",
            },
            warning: {
              50: "#FFFBEB",
              100: "#FEF3C7",
              200: "#FDE68A",
              300: "#FCD34D",
              400: "#FBBF24",
              500: "#F59E0B",
              600: "#D97706",
              700: "#B45309",
              800: "#92400E",
              900: "#78350F",
              DEFAULT: "#F59E0B",
              foreground: "#292524",
            },
            danger: {
              50: "#FFF1F2",
              100: "#FFE4E6",
              200: "#FECDD3",
              300: "#FDA4AF",
              400: "#FB7185",
              500: "#F43F5E",
              600: "#E11D48",
              700: "#BE123C",
              800: "#9F1239",
              900: "#881337",
              DEFAULT: "#E11D48",
              foreground: "#E7E5E4",
            },
          },
        },
      },
    }),
  ],
};
