import { FC, memo } from "react";

export type UnexpectedVectorProps = {
  height?: number;
};

export const UnexpectedVector: FC<UnexpectedVectorProps> = memo(
  ({ height = 80 }) => (
    <svg
      className="fill-gray-400"
      height={height}
      viewBox="0 0 64 80"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
    >
      <g>
        <path
          d="M57,7H7c-2.8,0-5,2.2-5,5v40c0,2.8,2.2,5,5,5h10h31h9c2.8,0,5-2.2,5-5V12C62,9.2,59.8,7,57,7z M60,12v5H41.3l2.4-8H57
		C58.7,9,60,10.4,60,12z M7,9h34.7l-2.4,8H4v-5C4,10.4,5.3,9,7,9z M18,55c0.2-1.1,1.2-2,2.4-2H26h12h6.5c1.2,0,2.2,0.9,2.4,2H18z
		 M17,41h-2c-0.6,0-1-0.5-1-1v-6c0-0.5,0.4-1,1-1h2V41z M19,30c0-1.1,0.9-2,2-2h22c1.1,0,2,0.9,2,2v14c0,1.1-0.9,2-2,2h-6H27h-6
		c-1.1,0-2-0.9-2-2V30z M26.2,26c0.4-1.2,1.5-2,2.8-2h6c1.3,0,2.4,0.8,2.8,2H26.2z M47,33h2c0.5,0,1,0.5,1,1v6c0,0.5-0.5,1-1,1h-2
		V33z M27.8,48h8.4l0.6,3h-9.6L27.8,48z M57,55h-8c-0.2-2.2-2.1-4-4.4-4h-5.7l-0.6-3H43c2.2,0,4-1.8,4-4v-1h2c1.7,0,3-1.3,3-3v-6
		c0-1.7-1.3-3-3-3h-2v-1c0-2.2-1.8-4-4-4h-3.1c-0.5-2.3-2.5-4-4.9-4h-6c-2.4,0-4.4,1.7-4.9,4H21c-2.2,0-4,1.8-4,4v1h-2
		c-1.6,0-3,1.3-3,3v6c0,1.7,1.4,3,3,3h2v1c0,2.2,1.8,4,4,4h4.8l-0.6,3h-4.7c-2.3,0-4.2,1.8-4.4,4H7c-1.7,0-3-1.3-3-3V19h56v33
		C60,53.7,58.7,55,57,55z"
        />
        <circle cx="54" cy="13.1" r="2" />
        <circle cx="48" cy="13.1" r="2" />
        <path d="M9,14.1h25c0.5,0,1-0.4,1-1s-0.5-1-1-1H9c-0.6,0-1,0.4-1,1S8.4,14.1,9,14.1z" />
        <path
          d="M23.8,36.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.4-1.4l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
		c0.4-0.4,0.4-1,0-1.4L28,33.5l1.4-1.4c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-1.4,1.4l-1.4-1.4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4
		l1.4,1.4l-1.4,1.4C23.4,35.3,23.4,35.9,23.8,36.3z"
        />
        <path
          d="M34.6,36.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.4-1.4l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
		c0.4-0.4,0.4-1,0-1.4l-1.4-1.4l1.4-1.4c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-1.4,1.4L36,30.7c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4
		l1.4,1.4l-1.4,1.4C34.2,35.3,34.2,35.9,34.6,36.3z"
        />
        <path
          d="M24.6,42.1c-0.3,0.5-0.2,1.1,0.3,1.4s1.1,0.2,1.4-0.3c0.1-0.1,1.7-2.4,5.7-2.4s5.7,2.3,5.7,2.4c0.2,0.3,0.5,0.5,0.8,0.5
		c0.2,0,0.4,0,0.5-0.2c0.5-0.3,0.6-0.9,0.3-1.4c-0.1-0.1-2.2-3.2-7.4-3.2S24.7,41.9,24.6,42.1z"
        />
      </g>
    </svg>
  ),
);
