import { AgentPersonality, NavigationItem } from "@types";

export type SidebarSection = {
  title: string;
  items: NavigationItem[];
};

export type Config = {
  navigation: {
    sidebar: SidebarSection[];
  };
  personality: {
    options: AgentPersonality[];
  };
  language: {
    options: Record<string, string>[];
  };
};

const config: Config = {
  navigation: {
    sidebar: [
      {
        title: "Top Section",
        items: [
          {
            href: "/company-selection",
            icon: "ic:outline-business",
            roles: new Set(["super", "owner"]),
            title: "Company Selection",
          },
          {
            href: "/playbooks",
            icon: "ic:outline-sports-football",
            title: "Playbooks",
            roles: new Set(["super"]),
          },
        ],
      },
      {
        title: "Bottom Section",
        items: [
          {
            href: "/conversations",
            icon: "ic:outline-reviews",
            title: "Conversations",
          },
          {
            href: "/agents",
            icon: "ic:outline-support-agent",
            title: "Agents",
          },
          {
            href: "/services",
            icon: "ic:outline-business-center",
            title: "Services",
          },
          {
            href: "/company",
            icon: "ic:outline-storefront",
            title: "Company",
          },
          {
            href: "/integrations",
            icon: "ic:outline-extension",
            title: "Integrations",
            roles: new Set(["super"]),
          },
          {
            href: "/team",
            icon: "ic:outline-people",
            title: "Team",
            roles: new Set(["admin", "owner", "super"]),
          },
        ],
      },
    ],
  },
  personality: {
    options: [
      "Good Neighbor",
      "Seasoned Pro",
      "Problem Solver",
      "Straightforward Expert",
      "Trustworthy Advisor",
      "Long-time Office Lady",
      "Guy at the Hardware Store",
      "Patient Tour Guide",
      "On-call Technician",
    ],
  },
  language: {
    options: [
      { key: "en", label: "English" },
      { key: "es", label: "Spanish" },
      { key: "fr", label: "French" },
      { key: "de", label: "German" },
      { key: "it", label: "Italian" },
      { key: "pt", label: "Portuguese" },
      { key: "zh", label: "Chinese" },
      { key: "ja", label: "Japanese" },
      { key: "ko", label: "Korean" },
      { key: "ru", label: "Russian" },
      { key: "tr", label: "Turkish" },
      { key: "vi", label: "Vietnamese" },
    ],
  },
};

export default config;
