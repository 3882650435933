import { useSession } from "@store";
import { useQuery } from "@tanstack/react-query";
import { Account } from "@types";
import axios from "axios";

type GetAccountByIdRequest = {
  accessToken?: string;
  accountId?: string;
};

const getAccountById = async ({
  accessToken,
  accountId,
}: GetAccountByIdRequest) => {
  if (!accountId) {
    return [];
  }
  try {
    const response = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/accounts/${accountId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch account");
  }
};

export const useGetAccountById = () => {
  const { accessToken, user, userHasRole } = useSession();
  return useQuery<Account>({
    queryKey: ["useGetAccountById", user?.accountId],
    enabled: userHasRole("owner"),
    queryFn: () => getAccountById({ accessToken, accountId: user?.accountId }),
  });
};
