import { memo } from "react";
import { NotFoundVector } from "./NotFoundVector";
import { SamedayLogo } from "@assets";
import { Button } from "@nextui-org/react";
import { useNavigateBack } from "@hooks";

export const NotFound = memo(() => {
  const navigateBack = useNavigateBack();
  return (
    <main className="flex h-screen w-full flex-col items-center justify-center">
      <div className="absolute top-10 left-10">
        <SamedayLogo height={40} />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col align-center gap-8">
          <div className="flex gap-8">
            <NotFoundVector height={80} />
            <div className="flex flex-col gap-4">
              <h1 className="text-4xl font-semibold">Uh - Oh!</h1>
              <h2 className="text-xl font-semibold">404 - Page Not Found</h2>
              <p>This page doesn't exist or was removed</p>
              <div className="flex mt-2">
                <Button onClick={navigateBack}>Go back!</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
});
