import { Icon } from "@iconify/react";
import { FC, ReactNode, memo } from "react";

export type SectionHeaderProps = {
  children?: ReactNode;
  icon?: string;
  title: string;
  subTitle?: string;
};

export const SectionHeader: FC<SectionHeaderProps> = memo(
  ({ children, title, subTitle, icon }) => {
    return (
      <header className="flex flex-row gap-4 items-center mt-0 my-4 justify-between">
        <div>
          <div className="flex flex-row items-center gap-2">
            {icon && <Icon height={24} icon={icon} />}
            <h2 className="capitalize font-medium text-2xl">{title}</h2>
          </div>
          {subTitle && <p className="my-2 text-sm text-gray-500">{subTitle}</p>}
        </div>
        <div className="flex gap-4">{children}</div>
      </header>
    );
  },
);
