import { Icon } from "@iconify/react/dist/iconify.js";
import { memo } from "react";

type CallSummaryProps = {
  summary: string;
};

export const CallSummary = memo(({ summary }: CallSummaryProps) => {
  return (
    <div className="py-4 px-5 rounded-xl bg-background-50 border border-background-700 min-w-[410px] max-w-[476px]">
      <div className="flex flex-col gap-2">
        <span className="flex items-center gap-2">
          <Icon
            color="#4ADE80"
            height={24}
            width={24}
            icon="fluent:sparkle-24-filled"
            name="call-summary"
          />
          <h2 className="text-xl font-semibold">Call Summary</h2>
        </span>
        <p className="text-sm">{summary}</p>
      </div>
    </div>
  );
});
