import { Input } from "@nextui-org/react";
import { ChangeEvent, FocusEvent, forwardRef, memo, useState } from "react";

type CurrencyInputProps = {
  className?: string;
  label: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<Element>) => void;
  value: string | number | undefined;
  isInvalid?: boolean;
  isDisabled?: boolean;
  variant?: "bordered" | "flat" | "underlined" | "faded";
  placeholder?: string;
  size?: "sm" | "md" | "lg";
  errorMessage?: string;
};

const formatCurrency = (value: string | number | undefined): string => {
  if (!value) return "";
  if (typeof value === "number") {
    const formatted = value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formatted;
  }

  const numericValue = parseFloat(value.replace(/[$,]/g, ""));
  if (isNaN(numericValue)) {
    return value;
  } else {
    return numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const CurrencyInput = memo(
  forwardRef<HTMLInputElement, CurrencyInputProps>(
    (
      {
        className,
        value,
        onChange,
        onBlur,
        isDisabled,
        isInvalid,
        name,
        label,
        variant,
        placeholder,
        size,
        errorMessage,
      },
      ref,
    ) => {
      const [internalValue, setInternalValue] = useState(formatCurrency(value));

      const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setInternalValue(inputValue);
        onChange(e);
      };

      const handleInputBlur = (e: FocusEvent<Element>) => {
        const formattedValue = formatCurrency(internalValue);
        setInternalValue(formattedValue);
        onBlur && onBlur(e);
      };

      return (
        <Input
          className={className}
          errorMessage={errorMessage}
          label={label}
          ref={ref}
          type="text"
          name={name}
          isInvalid={isInvalid}
          size={size}
          startContent={
            <div className="pointer-events-none flex items-center">
              <span className={`text-sm ${isInvalid ? "text-danger-500" : ""}`}>$</span>
            </div>
          }
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          value={internalValue}
          isDisabled={isDisabled}
          variant={variant}
          placeholder={placeholder}
        />
      );
    },
  ),
);
