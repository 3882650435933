import { useScreen } from "@hooks";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useCallback } from "react";

type Env = "local" | "stg";

export type UIState = {
  apiURL: string;
  env: Env;
  sidebarOpen: boolean;
};

const initialState: UIState = {
  apiURL: localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL,
  env: (localStorage.getItem("env") as Env) || "stg",
  sidebarOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    closeSidebar: (state) => {
      state.sidebarOpen = false;
    },
    openSidebar: (state) => {
      state.sidebarOpen = true;
    },
    toggleEnv: (state) => {
      state.apiURL =
        state.env === "local"
          ? import.meta.env.VITE_DALE_API_URL
          : "http://localhost";
      state.env = state.env === "local" ? "stg" : "local";
      localStorage.setItem("apiURL", state.apiURL);
      localStorage.setItem("env", state.env);
    },
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    useLocalEnv: (state) => {
      state.env = "local";
      state.apiURL = "http://localhost";
      localStorage.setItem("apiURL", "http://localhost");
      localStorage.setItem("env", "local");
    },
    useStgEnv: (state) => {
      state.env = "stg";
      state.apiURL = import.meta.env.VITE_DALE_API_URL;
      localStorage.setItem("apiURL", import.meta.env.VITE_DALE_API_URL);
      localStorage.setItem("env", "stg");
    },
  },
});

const apiURL = (state: RootState) => state.ui.apiURL;
const env = (state: RootState) => state.ui.env;
const sidebarOpen = (state: RootState) => state.ui.sidebarOpen;

export const { reducer } = uiSlice;

export const useUI = () => {
  const dispatch = useAppDispatch();
  const { isWide } = useScreen();
  return {
    apiURL: useAppSelector(apiURL),
    env: useAppSelector(env),
    closeSidebar: useCallback(
      () => !isWide && dispatch(uiSlice.actions.closeSidebar()),
      [dispatch, isWide],
    ),
    openSidebar: useCallback(
      () => dispatch(uiSlice.actions.openSidebar()),
      [dispatch],
    ),
    sidebarOpen: useAppSelector(sidebarOpen),
    toggleEnv: useCallback(
      () => dispatch(uiSlice.actions.toggleEnv()),
      [dispatch],
    ),
    toggleSidebar: useCallback(
      () => dispatch(uiSlice.actions.toggleSidebar()),
      [dispatch],
    ),
    useLocalEnv: useCallback(
      () => dispatch(uiSlice.actions.useLocalEnv()),
      [dispatch],
    ),
    useStgEnv: useCallback(
      () => dispatch(uiSlice.actions.useStgEnv()),
      [dispatch],
    ),
  };
};
