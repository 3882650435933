import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Slider } from "@nextui-org/react";
import { useEffect, useRef, useState, memo } from "react";

interface AudioPlayerProps {
  src: string | undefined;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = memo(({ src }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.src = src ? src : "";
      audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration);
      });
      audio.addEventListener("timeupdate", () => {
        setCurrentTime(audio.currentTime);
      });
      audio.addEventListener("ended", () => {
        setCurrentTime(duration); // Update currentTime to duration when audio ends
      });
    }
    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", () => {});
        audio.removeEventListener("timeupdate", () => {});
        audio.removeEventListener("ended", () => {});
      }
    };
  }, [duration, src]);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false); // Update isPlaying state after pausing
      } else {
        audio
          .play()
          .then(() => {
            setIsPlaying(true); // Update isPlaying state after playing
          })
          .catch((error) => {
            console.error("Playback was not successful", error);
          });
      }
    }
  };
  const handleSeek = (value: number | number[]) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = typeof value === "number" ? value : value[0];
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="bg-default-100 text-[#ffc107] flex flex-row gap-2 items-center p-3 rounded-full">
      <Button
        isIconOnly
        variant="light"
        radius="full"
        size="sm"
        className="text-lg outline-none focus:outline-none"
        onClick={handlePlayPause}
      >
        {isPlaying ? (
          <Icon width={48} height={48} icon="fluent:pause-circle-48-regular" />
        ) : (
          <Icon width={48} height={48} icon="fluent:play-circle-48-regular" />
        )}
      </Button>
      <p className="flex flex-row items-center gap-1">
        <span className="text-foreground text-sm">
          {formatTime(currentTime)}
        </span>
        <span className="text-foreground text-sm">/</span>
        <span className="text-foreground text-sm">{formatTime(duration)}</span>
      </p>
      <Slider
        aria-label="Player progress"
        size="sm"
        hideThumb={true}
        minValue={0}
        maxValue={duration}
        value={currentTime}
        onChange={handleSeek}
        className="max-w-md"
      />
      <audio ref={audioRef} />
    </div>
  );
});
