import {
  Avatar,
  Button,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
} from "@nextui-org/react";
import { useSession, useUI } from "@store";
import { Company, User } from "@types";
import { FC, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeSwitch } from "../ThemeSwitch";
import { Icon } from "@iconify/react/dist/iconify.js";

export type SidebarAvatarProps = {
  company?: Company;
  user?: User;
};

export const UserMenu: FC<SidebarAvatarProps> = memo(({ company, user }) => {
  const navigate = useNavigate();
  const { logout } = useSession();
  const { closeSidebar, env, toggleEnv } = useUI();
  const logoutHandler = useCallback(() => {
    logout();
    navigate("/login");
  }, [logout, navigate]);
  return (
    <Popover showArrow>
      <PopoverTrigger>
        <Button
          className="flex gap-2 h-20 items-center justify-start"
          fullWidth
          variant="bordered"
        >
          <Avatar
            showFallback
            fallback={<Icon height={20} icon="ic:outline-tag-faces" />}
            size="sm"
            name={user?.name}
          />
          <div className="flex flex-col items-start">
            <p className="text-small text-foreground">
              {user?.name || company?.name}
            </p>
            <p className="text-ellipsis overflow-hidden text-tiny text-foreground-400">
              {user?.email}
            </p>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-2 p-2 items-start w-72">
        {import.meta.env.DEV && (
          <Switch
            className="flex flex-row-reverse justify-between max-w-none mb-2 pl-6 pt-4 w-full"
            color="secondary"
            onChange={toggleEnv}
            size="md"
            isSelected={env === "local"}
          >
            Use Local
          </Switch>
        )}
        <ThemeSwitch />
        <Button
          as={Link}
          className="flex justify-start"
          fullWidth
          href="/user-settings"
          onPress={closeSidebar}
          size="lg"
          variant="light"
        >
          Settings
        </Button>
        <Button
          className="flex justify-start"
          fullWidth
          onPress={logoutHandler}
          size="lg"
          variant="light"
        >
          Logout
        </Button>
      </PopoverContent>
    </Popover>
  );
});
