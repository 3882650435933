import { AuthenticatedError } from "@pages";
import { ErrorResponse, Navigate, useRouteError } from "react-router-dom";

export const AuthErrorBoundary = () => {
  const error = useRouteError() as ErrorResponse & Error;
  if (error?.status === 401) {
    return <Navigate to="/login" replace />;
  }
  return <AuthenticatedError error={error} />;
};
