import {
  useRouteError,
  Navigate,
  isRouteErrorResponse,
} from "react-router-dom";
import { NotFound } from "@pages";

export const RootErrorBoundary = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error?.status === 401) {
      return <Navigate to="/login" replace />;
    }
    if (error?.status === 404) {
      return <NotFound />;
    }
  } else {
    return <div>An unexpected error occurred</div>;
  }
};
