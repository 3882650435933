import config from "@config";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AuthResponse, Resource, Role, User } from "@types";
import { RootState } from "./store";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useCallback } from "react";

export type SessionState = {
  accessToken: string;
  companyId?: string;
  user?: User;
};

const initialState: SessionState = {
  accessToken: localStorage.getItem("accessToken") || "",
  companyId: localStorage.getItem("companyId") || undefined,
  user:
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user") || ""),
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthResponse>) => {
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("expiration", action.payload.expiration.toString());
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem(
        "companyId",
        action.payload.user.companyId || action.payload.user.companyIds[0],
      );
      state.companyId =
        action.payload.user.companyId || action.payload.user.companyIds[0];
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    logout: (state) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("expiration");
      localStorage.removeItem("user");
      localStorage.removeItem("companyId");
      state.user = undefined;
      state.companyId = undefined;
    },
    setCompanyId: (state, action: PayloadAction<string>) => {
      localStorage.setItem("companyId", action.payload);
      state.companyId = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },
  },
});

const accessToken = (state: RootState) => state.session.accessToken;

const companyId = (state: RootState) => state.session.companyId;

const user = (state: RootState) => state.session.user;

const userHasAccess = createSelector(
  [user],
  (user) => (item: Resource) =>
    item.roles ? item.roles.has(user?.role as Role) : true,
);

const userHasRole = createSelector(
  [user],
  (user) =>
    (...roles: Role[]) =>
      roles.includes(user?.role || "member"),
);

const userIsAuthorized = createSelector(
  [userHasAccess],
  (userHasAccess) => (pathname: string) => {
    const allNavigationItems = config.navigation.sidebar.flatMap(
      (section) => section.items,
    );
    const route = allNavigationItems.find((item) =>
      pathname.includes(item.href),
    );
    if (route) {
      return userHasAccess(route as Resource);
    } else {
      return true;
    }
  },
);

const userSubRoles = createSelector([userHasRole], (userHasRole) =>
  userHasRole("super", "owner")
    ? ["owner", "admin", "member"]
    : userHasRole("admin")
      ? ["member"]
      : [],
);

export const { reducer } = sessionSlice;

export const useSession = () => {
  const dispatch = useAppDispatch();
  return {
    accessToken: useAppSelector(accessToken),
    companyId: useAppSelector(companyId),
    login: useCallback(
      (data: AuthResponse) => dispatch(sessionSlice.actions.login(data)),
      [dispatch],
    ),
    logout: useCallback(
      () => dispatch(sessionSlice.actions.logout()),
      [dispatch],
    ),
    setCompanyId: useCallback(
      (id: string) => dispatch(sessionSlice.actions.setCompanyId(id)),
      [dispatch],
    ),
    setUser: useCallback(
      (user: User) => dispatch(sessionSlice.actions.setUser(user)),
      [dispatch],
    ),
    user: useAppSelector(user),
    userHasAccess: useAppSelector(userHasAccess),
    userHasRole: useAppSelector(userHasRole),
    userIsAuthorized: useAppSelector(userIsAuthorized),
    userSubRoles: useAppSelector(userSubRoles),
  };
};
