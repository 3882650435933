import { SamedayLogo } from "@assets";
import config from "@config";
import { useScreen } from "@hooks";
import { Icon } from "@iconify/react";
import { Button } from "@nextui-org/react";
import { useCompaniesById, useGetAccountById } from "@services";
import { useSession, useUI } from "@store";
import { NavigationItem } from "@types";
import { useClickAway } from "@uidotdev/usehooks";
import { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { CompanySelection } from "./CompanySelection";
import { Navigation } from "../Navigation";
import { UserMenu } from "./UserMenu";
import { Box, Divider } from "@mui/material";

export const Sidebar = memo(() => {
  const { user, userHasAccess } = useSession();
  const account = useGetAccountById();
  const { closeSidebar, sidebarOpen } = useUI();
  const { isWide } = useScreen();
  const ref = useClickAway<HTMLDivElement>(closeSidebar);
  const location = useLocation();
  const companies = useCompaniesById();
  const multiCompanyOwner = useMemo(
    () => user?.role === "owner" && account?.data?.type === "MultiCompany",
    [user?.role, account?.data?.type],
  );

  const showSuperItems = useMemo(
    () => user?.role === "super" || multiCompanyOwner,
    [user?.role, multiCompanyOwner],
  );

  return (
    <Box
      component="div"
      ref={ref}
      sx={{
        backgroundColor: "grey.100",
        borderRight: 1,
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.700" : "grey.300",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "100vh",
        alignItems: "center",
        px: 2.5,
        py: 3,
        top: 0,
        transition: "left 0.3s",
        width: 288,
        zIndex: 10,
        left: sidebarOpen ? 0 : -288,
      }}
    >
      <div className="items-center justify-between flex w-full">
        <SamedayLogo />
        {!isWide && (
          <Button
            isIconOnly
            onClick={closeSidebar}
            size="md"
            startContent={<Icon icon="fluent:arrow-export-rtl-24-filled" />}
            title="Toggle Sidebar"
            variant="light"
          />
        )}
      </div>
      {showSuperItems && <CompanySelection />}
      <div className="flex flex-col h-full w-full">
        {config.navigation.sidebar.map((section, index) => (
          <div key={index}>
            <Navigation
              items={section.items.filter((item: NavigationItem) => {
                if (item.href === "/company-selection") {
                  return user?.role === "super" || multiCompanyOwner;
                } else {
                  return userHasAccess(item);
                }
              })}
              label={`Sidebar Section: ${section.title}`}
              selected={`/${location.pathname.split("/")[1]}`}
            />
            {showSuperItems && index < config.navigation.sidebar.length - 1 && (
              <Divider sx={{ my: 2 }} />
            )}
          </div>
        ))}
      </div>
      <UserMenu company={companies.data} user={user} />
    </Box>
  );
});
