import { NextUIProvider } from "@nextui-org/react";
import { store } from "@store";
import { QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider, useTheme } from "next-themes";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { StrictMode } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { queryClient } from "./queryClient";
import { router } from "./router";
import { theme } from "../theme";
import "./index.css";
import "react-toastify/dist/ReactToastify.min.css";

const App = () => {
  const { theme } = useTheme();
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer hideProgressBar theme={theme} autoClose={1000} />
    </>
  );
};

const Providers = () => (
  <StrictMode>
    <NextUIProvider navigate={router.navigate}>
      <ThemeProvider attribute="class" defaultTheme="light">
        <MuiThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ReduxProvider store={store}>
              <App />
            </ReduxProvider>
          </QueryClientProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </NextUIProvider>
  </StrictMode>
);

export default Providers;
