import { AuthenticatedLayout } from "@layouts";
import { FC, memo } from "react";
import { ErrorInfo } from "@components";

export type AuthenticatedErrorProps = {
  status?: number;
  error: Error;
};

export const AuthenticatedError: FC<AuthenticatedErrorProps> = memo(
  ({ error }) => {
    console.log(error);
    return (
      <AuthenticatedLayout description="" title="Unexpected Error">
        <ErrorInfo
          title="Uh - Oh!"
          subtitle="Something went wrong"
          description="We are working to fix this issue"
        />
      </AuthenticatedLayout>
    );
  },
);
