import { FC, memo } from "react";
import { NavigationListItem } from "./Sidebar/NavigationListItem";
import { NavigationItem } from "@types";
import { useSession } from "@store";

export type NavigationProps = {
  items: NavigationItem[];
  label: string;
  selected: string;
};

export const Navigation: FC<NavigationProps> = memo(
  ({ items, label, selected }) => {
    const { userHasRole } = useSession();
    const navItems = items.filter((item) => {
      if (item.superOnly) {
        return userHasRole("super");
      }
      return true;
    });
    return (
      <nav
        aria-label={label}
        className="flex flex-col items-center gap-2 w-full"
      >
        {navItems.map((item) => (
          <NavigationListItem
            item={item}
            key={item.href}
            selected={selected === item.href}
          />
        ))}
      </nav>
    );
  },
);
