import { configureStore } from "@reduxjs/toolkit";
import { reducer as serviceTitanReducer } from "./serviceTitanIntegration";
import { reducer as sessionReducer } from "./session";
import { reducer as uiReducer } from "./ui";

export const store = configureStore({
  reducer: {
    serviceTitan: serviceTitanReducer,
    session: sessionReducer,
    ui: uiReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
