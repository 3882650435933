import { ErrorInfo, PageHeader, Sidebar } from "@components";
import { useScreen } from "@hooks";
import { useSession, useUI } from "@store";
import { PendoWindow, TabItem } from "@types";
import { FC, ReactNode, memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

export type AuthenticatedLayoutProps = {
  actions?: ReactNode;
  children: ReactNode;
  icon?: string;
  description: string;
  showPhoneNumber?: boolean;
  tabs?: TabItem[];
  title: string;
};

export const AuthenticatedLayout: FC<AuthenticatedLayoutProps> = memo(
  ({ actions, children, description, icon, showPhoneNumber, title, tabs }) => {
    const location = useLocation();
    const { userIsAuthorized, user } = useSession();
    const { isWide } = useScreen();
    const { openSidebar } = useUI();

    useEffect(() => {
      isWide && openSidebar();
    }, [isWide, openSidebar]);

    useEffect(() => {
      if ((window as PendoWindow)?.pendo && user) {
        (window as PendoWindow)?.pendo?.initialize({
          visitor: {
            id: user?.id,
            email: user?.email,
            role: user?.role,
          },
          account: {
            id: user?.companyId,
          },
        });
      }
    }, [user]);

    return (
      <>
        <main className="xl:ml-72 h-screen overflow-auto flex flex-col">
          <PageHeader
            icon={icon}
            description={description}
            tabs={tabs}
            showPhoneNumber={showPhoneNumber}
            title={title}
          >
            {actions}
          </PageHeader>
          <div className="py-10 px-10 grow flex flex-col overscroll-contain bg-background">
            {userIsAuthorized(location.pathname) ? (
              children
            ) : (
              <ErrorInfo
                description="You are not authorized"
                subtitle="Access denied"
                title="Forbidden"
              />
            )}
          </div>
        </main>
        <Sidebar />
      </>
    );
  },
);
