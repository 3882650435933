import { Icon } from "@iconify/react/dist/iconify.js";
import clsx from "clsx";
import { FC, memo, ReactNode } from "react";

export type DisclaimerProps = {
  children?: ReactNode;
  description: string;
  title: string;
  type: "error" | "info" | "success" | "warning";
};

const icons = {
  error: "fluent:error-circle-24-regular",
  info: "fluent:info-24-regular",
  success: "fluent:checkmark-circle-24-regular",
  warning: "fluent:warning-24-regular",
};

// TODO: needs to complete the color palette
export const Disclaimer: FC<DisclaimerProps> = memo(
  ({ children, description, title, type }) => (
    <div
      className={clsx(
        "bg-background-50 border-solid border-1 flex flex-row gap-4 items-center p-4 rounded-md",
        {
          "bg-danger-50": type === "error",
          "bg-blue-50": type === "info",
          "border-success-400": type === "success",
          "border-warning-400": type === "warning",
        },
      )}
    >
      <Icon
        className={clsx({
          "text-success-400": type === "success",
          "text-warning-400": type === "warning",
        })}
        height={36}
        icon={icons[type]}
      />
      <div className="flex flex-grow justify-between items-center">
        <div className="flex flex-col">
          <h3
            className={clsx("font-semibold text-xl", {
              "text-success-400": type === "success",
              "text-warning-400": type === "warning",
            })}
          >
            {title}
          </h3>
          <p className="text-sm">{description}</p>
        </div>
        <div>{children}</div>
      </div>
    </div>
  ),
);
