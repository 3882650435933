import { Tabs, Tab } from "@nextui-org/react";
import { useTheme } from "next-themes";
import { Outlet, useLocation } from "react-router-dom";
import { FC, memo, ReactNode } from "react";

export type TabNavProps = {
  items: { content?: ReactNode; href: string; title: string }[];
  label: string;
};

export const TabNav: FC<TabNavProps> = memo(({ items, label }) => {
  const location = useLocation();
  const { theme } = useTheme();
  return (
    <Tabs
      aria-label={label}
      classNames={{
        cursor: theme === "light" ? "!bg-white" : "!bg-stone-950",
        tab: "data-[selected=true]:font-bold data-[disabled=true]:text-stone-700",
        tabList: theme === "light" && "bg-stone-100",
        panel: "mt-6",
      }}
      items={items}
      selectedKey={location.pathname}
    >
      {({ href, title }) => (
        <Tab
          aria-label={title}
          className="flex grow"
          href={href}
          key={href}
          title={title}
        >
          <Outlet />
        </Tab>
      )}
    </Tabs>
  );
});
