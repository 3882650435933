import { parseISO, format } from "date-fns";

export const getUTCDatetime = (date: string): Date => parseISO(`${date}Z`);

/**
 *
 * @param dateString The date string in python timestamp format (2024-06-11T21:34:09.726000) it is missing the timezone information so we need to append Z to the end of the string
 * @returns string formatted in local time
 */
export const formatDate = (dateString: string): string => {
  if (!dateString) return "";
  const date = getUTCDatetime(dateString);
  const formattedDateTime = format(date, "MMMM d, yyyy h:mm:ss a");
  return formattedDateTime;
};

export const formatPascalCase = (pascalCaseString: string): string => {
  if (!pascalCaseString) return "";
  const separatedString = pascalCaseString.replace(/([A-Z])/g, " $1").trim();
  return separatedString.charAt(0).toUpperCase() + separatedString.slice(1);
};
