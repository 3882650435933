import { FC, memo } from "react";

export type NotFoundVectorProps = {
  height?: number;
};

export const NotFoundVector: FC<NotFoundVectorProps> = memo(
  ({ height = 100 }) => (
    <svg
      className="fill-slate-300"
      height={height}
      viewBox="0 0 110 135"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
    >
      <path
        d="M20.6,14.6h65.7V6.3H6.6v107.8h10.8V17.7C17.4,16,18.8,14.6,20.6,14.6L20.6,14.6z M92.6,14.6h14c1.7,0,3.2,1.4,3.2,3.2v91.8
	c0,0.9-0.4,1.7-1,2.3l-22.3,22.3c-0.6,0.6-1.4,0.9-2.2,0.9H20.6c-1.7,0-3.2-1.4-3.2-3.2v-11.4h-14c-1.7,0-3.2-1.4-3.2-3.2V3.2
	C0.3,1.4,1.7,0,3.5,0h86c1.7,0,3.2,1.4,3.2,3.2L92.6,14.6L92.6,14.6z M37.5,38.7c-1.2-1.2-1.2-3.2,0-4.5c1.2-1.2,3.2-1.2,4.5,0
	l4.3,4.3l4.3-4.3c1.2-1.2,3.2-1.2,4.5,0c1.2,1.2,1.2,3.2,0,4.5L50.7,43l4.3,4.3c1.2,1.2,1.2,3.2,0,4.5c-1.2,1.2-3.2,1.2-4.5,0
	l-4.3-4.3L42,51.8c-1.2,1.2-3.2,1.2-4.5,0c-1.2-1.2-1.2-3.2,0-4.5l4.3-4.3L37.5,38.7z M85.2,34.3c1.2-1.2,3.2-1.2,4.5,0
	c1.2,1.2,1.2,3.2,0,4.5L85.3,43l4.3,4.3c1.2,1.2,1.2,3.2,0,4.5c-1.2,1.2-3.2,1.2-4.5,0l-4.3-4.3l-4.3,4.3c-1.2,1.2-3.2,1.2-4.5,0
	c-1.2-1.2-1.2-3.2,0-4.5l4.3-4.3l-4.3-4.3c-1.2-1.2-1.2-3.2,0-4.5c1.2-1.2,3.2-1.2,4.5,0l4.3,4.3L85.2,34.3z M49.1,75.1
	c-0.9,1.5-2.9,1.9-4.4,1c-1.5-0.9-1.9-2.9-1-4.4c2.1-3.4,5-6.2,8.5-8.1c3.4-1.9,7.2-3,11.3-3c4.1,0,8,1.1,11.3,3
	c3.5,1.9,6.4,4.8,8.5,8.1c0.9,1.5,0.5,3.4-1,4.4c-1.5,0.9-3.4,0.5-4.4-1c-1.5-2.5-3.6-4.5-6.2-5.9c-2.4-1.4-5.2-2.1-8.3-2.1
	c-3,0-5.8,0.8-8.3,2.1C52.8,70.6,50.7,72.7,49.1,75.1L49.1,75.1z M98.9,112.7H87.4v11.5L98.9,112.7z M81,128.7v-19.2
	c0-1.7,1.4-3.2,3.2-3.2h19.2V97H23.7v31.6L81,128.7z M23.7,90.7h79.6V20.9H23.7L23.7,90.7z"
      />
    </svg>
  ),
);
