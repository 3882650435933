import { Icon } from "@iconify/react";
import { Switch } from "@nextui-org/react";
import { useTheme } from "next-themes";
import { memo, useCallback } from "react";

export const ThemeSwitch = memo(() => {
  const { theme, setTheme } = useTheme();
  const clickHandler = useCallback(
    () => setTheme(theme === "dark" ? "light" : "dark"),
    [theme, setTheme],
  );
  return (
    <Switch
      className="flex flex-row-reverse justify-between max-w-none mb-2 pl-6 pt-4 w-full"
      color="secondary"
      endContent={<Icon icon="material-symbols:dark-mode" />}
      onClick={clickHandler}
      size="md"
      startContent={<Icon icon="material-symbols:light-mode-rounded" />}
    >
      Dark mode
    </Switch>
  );
});
