import { useScreen } from "@hooks";
import { Tabs, Tab } from "@mui/material";
import { Icon } from "@iconify/react";
import { Button, Chip } from "@nextui-org/react";
import { useCompaniesById } from "@services";
import { useUI } from "@store";
import { formatPhoneNumber } from "@utils/phoneNumber";
import { memo, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { TabItem } from "@types";
type PageHeaderProps = {
  children?: ReactNode;
  description: string;
  icon?: string;
  showPhoneNumber?: boolean;
  title: string;
  tabs?: TabItem[];
};

export const PageHeader = memo(
  ({
    children,
    description,
    icon,
    showPhoneNumber,
    title,
    tabs,
  }: PageHeaderProps) => {
    const navigate = useNavigate();
    const { data, isPending } = useCompaniesById();
    const phoneNumber = isPending ? "" : data?.phone;
    const { isWide } = useScreen();
    const { openSidebar } = useUI();
    return (
      <header
        className={`bg-background-50 flex px-10 pt-5 min-h-28 items-center justify-between ${
          tabs?.length ? "pb-0" : "pb-5"
        }`}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
            {!isWide ? (
              <Button
                isIconOnly
                onClick={openSidebar}
                size="md"
                title="Toggle Sidebar"
                variant="flat"
              >
                <Icon icon="fluent:navigation-24-regular" />
              </Button>
            ) : (
              icon && <Icon height={32} icon={icon} />
            )}
            <h1 className="text-4xl">{title}</h1>
            {showPhoneNumber && (
              <Chip color="success" radius="sm" variant="dot">
                <p className="font-menlo">
                  {formatPhoneNumber(phoneNumber || "")}
                </p>
              </Chip>
            )}
          </div>
          <h6 className="text-small">{description}</h6>
          {tabs && tabs.length > 0 && (
            <Tabs
              sx={(theme) => ({
                "& .MuiTab-root": {
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: theme.palette.text.secondary,
                  },
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.text.secondary,
                },
              })}
              value={tabs.findIndex((tab) => tab.path === location.pathname)}
              onChange={(_, newValue) => navigate(tabs[newValue].path)}
            >
              {tabs.map((tab) => (
                <Tab key={tab.path} label={tab.label} disabled={tab.disabled} />
              ))}
            </Tabs>
          )}
        </div>
        <div>{children}</div>
      </header>
    );
  },
);
