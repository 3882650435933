import { Select, SelectItem } from "@nextui-org/react";
import { defaultCustomerStatusOptions } from "@utils/customers";
import { formatPascalCase } from "@utils/string";
import { ChangeEvent, FocusEvent, memo, forwardRef } from "react";
import { useCompaniesById } from "@services";

type CustomerStatusSelectProps = {
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: FocusEvent<Element, Element>) => void;
  label?: string;
  placeholder?: string;
  value?: string;
  name?: string;
};

export const CustomerStatusSelect = memo(
  forwardRef<HTMLSelectElement, CustomerStatusSelectProps>(
    ({ onChange, onBlur, label, placeholder, value, name }, ref) => {
      const company = useCompaniesById();
      return (
        <Select
          ref={ref}
          items={
            company.data?.customerCategories.map((statuses) => ({
              label: formatPascalCase(statuses),
              value: statuses,
            })) || defaultCustomerStatusOptions
          }
          label={label}
          placeholder={placeholder}
          value={value}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
        >
          {(status) => (
            <SelectItem key={status.value} value={status.value}>
              {status.label}
            </SelectItem>
          )}
        </Select>
      );
    },
  ),
);
