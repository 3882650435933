import { redirect } from "react-router-dom";
import axios from "axios";
import { store } from "@store";
import { sessionSlice } from "@store";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      store.dispatch(sessionSlice.actions.logout());
      return redirect("/login");
    }
    return Promise.reject(error);
  },
);

export const authLoader = () => {
  const isAuthenticated = () => {
    const accessToken = localStorage.getItem("accessToken");
    const expiration = Number(localStorage.getItem("expiration") || "");
    const currentTime = Math.floor(Date.now() / 1000);

    if (!accessToken || !expiration || expiration <= currentTime) {
      store.dispatch(sessionSlice.actions.logout());
      return false;
    }
    return true;
  };

  if (!isAuthenticated()) {
    return redirect("/login");
  }
  return null;
};
