import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useCallback } from "react";

type CustomerInfo = {
  id: number;
  name: string;
  phone: string;
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  locations: {
    id: number;
    name: string;
    address: {
      street: string;
      city: string;
      state: string;
      zip: string;
      country: string;
    };
  }[];
};

type JobType = {
  id: number;
  name: string;
  summary: string;
  businessUnitIds: number[];
  skills: string[];
};

interface ServiceTitanState {
  jobType: JobType | null;
  customerInfo: CustomerInfo | null;
  appointmentJobId: number | null;
  showAddCustomerForm: boolean;
}

const initialState: ServiceTitanState = {
  jobType: null,
  customerInfo: null,
  appointmentJobId: null,
  showAddCustomerForm: false,
};

export const serviceTitanSlice = createSlice({
  name: "serviceTitan",
  initialState,
  reducers: {
    setAppointmentJobId: (state, action: PayloadAction<number>) => {
      state.appointmentJobId = action.payload;
    },
    setCustomerInfo: (state, action: PayloadAction<CustomerInfo | null>) => {
      state.customerInfo = action.payload;
    },
    setJobType: (state, action: PayloadAction<JobType>) => {
      state.jobType = action.payload;
    },
    setShowAddCustomerForm: (state, action: PayloadAction<boolean>) => {
      state.showAddCustomerForm = action.payload;
    },
  },
});

export const {
  setJobType,
  setCustomerInfo,
  setAppointmentJobId,
  setShowAddCustomerForm,
} = serviceTitanSlice.actions;

const selectJobType = (state: RootState) => state.serviceTitan.jobType;
const selectCustomerInfo = (state: RootState) =>
  state.serviceTitan.customerInfo;
const selectAppointmentJobId = (state: RootState) =>
  state.serviceTitan.appointmentJobId;
const selectShowAddCustomerForm = (state: RootState) =>
  state.serviceTitan.showAddCustomerForm;

export const { reducer } = serviceTitanSlice;

export const useServiceTitanIntegration = () => {
  const dispatch = useAppDispatch();
  return {
    jobType: useAppSelector(selectJobType),
    customerInfo: useAppSelector(selectCustomerInfo),
    appointmentJobId: useAppSelector(selectAppointmentJobId),
    showAddCustomerForm: useAppSelector(selectShowAddCustomerForm),
    setAppointmentJobId: useCallback(
      (appointmentJobId: number) =>
        dispatch(setAppointmentJobId(appointmentJobId)),
      [dispatch],
    ),
    setCustomerInfo: useCallback(
      (customerInfo: CustomerInfo | null) =>
        dispatch(setCustomerInfo(customerInfo)),
      [dispatch],
    ),
    setJobType: useCallback(
      (jobType: JobType) => dispatch(setJobType(jobType)),
      [dispatch],
    ),
    setShowAddCustomerForm: useCallback(
      (showAddCustomerForm: boolean) =>
        dispatch(setShowAddCustomerForm(showAddCustomerForm)),
      [dispatch],
    ),
  };
};
