import { Icon } from "@iconify/react";
import { Button } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { useUI } from "@store";
import { NavigationItem } from "@types";
import clsx from "clsx";
import { FC, memo } from "react";

export type NavigationListItemProps = {
  item: NavigationItem;
  selected: boolean;
};

export const NavigationListItem: FC<NavigationListItemProps> = memo(
  ({ item, selected }) => {
    const { closeSidebar } = useUI();
    return (
      <Button
        as={Link}
        className={clsx("justify-start", { "text-foreground": !selected })}
        color="primary"
        to={item.href}
        isDisabled={item.disabled}
        fullWidth
        onPress={closeSidebar}
        startContent={<Icon height={20} icon={item.icon} />}
        variant={selected ? "solid" : "light"}
      >
        {item.title}
      </Button>
    );
  },
);
