import Message from "./Message";
import { memo } from "react";

export const Transcript = memo(({ transcript }: { transcript: string }) => {
  if (!transcript) return <div className="text-center">No transcript</div>;
  const messages = transcript.split("\n").filter(Boolean);
  return (
    <div className="flex flex-col gap-2">
      {messages.map((message, index) => {
        const [speaker, text] = message.split(":");
        const speakerType = speaker === "User" ? "User" : "AI";
        return <Message key={index} speaker={speakerType} text={text} />;
      })}
    </div>
  );
});
