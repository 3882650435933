import { useSession } from "@store";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface CompaniesRequest {
  accessToken?: string;
  name?: string;
  page?: number;
  size?: number;
}

const getCompanies = async ({
  accessToken,
  name,
  page,
  size,
}: CompaniesRequest) => {
  try {
    const response = await axios.get(
      `${localStorage.getItem("apiURL") || import.meta.env.VITE_DALE_API_URL}/companies/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          name,
          page,
          size,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch companies", error);
    throw error;
  }
};

export const useGetCompanies = ({
  name,
  page = 1,
  size = 50,
}: {
  name: string;
  page?: number;
  size?: number;
}) => {
  const { accessToken, userHasRole } = useSession();
  return useQuery({
    enabled: userHasRole("super", "owner"),
    queryKey: ["useGetCompanies", name, page, size],
    queryFn: () => getCompanies({ accessToken, name, page, size }),
  });
};
