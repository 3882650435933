import { useEffect, useState } from "react";
import config from "../../../tailwind.config";

const screens = Object.entries(config.theme.screens).reduce(
  (acc, [key, value]) => {
    acc[parseInt(value.replace("px", ""))] = key;
    return acc;
  },
  {} as Record<string, string>,
);

const breakpoints = Object.keys(screens).sort(
  (a, b) => parseInt(b) - parseInt(a),
);

export const useScreen = () => {
  const [size, setSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const breakpoint =
    breakpoints.find((screen) => size.width && size.width > parseInt(screen)) ||
    0;
  const isWide = screens[breakpoint] === "xl" || screens[breakpoint] === "2xl";
  useEffect(() => {
    window.addEventListener("resize", () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);
  return {
    ...size,
    isWide,
    breakpoint: screens[breakpoint],
    screens: config.theme.screens,
  };
};
