import { ChipProps } from "@nextui-org/react";
import { ConversationStatus, CustomerStatus } from "@types";

export const conversationStatusColorMap: Record<
  ConversationStatus,
  ChipProps["color"]
> = {
  ended: "success",
  forwarding: "secondary",
  "in-progress": "warning",
  queued: "default",
  ringing: "danger",
  started: "default",
  stopped: "danger",
  verified: "success",
};

export const getConversationStatusOptions = () =>
  Object.keys(conversationStatusColorMap);

export const getConversationStatusColor = (
  status?: ConversationStatus,
): ChipProps["color"] =>
  status ? conversationStatusColorMap[status] : "default";

export const customerStatusColorMap: Record<
  CustomerStatus,
  ChipProps["color"]
> = {
  callBack: "secondary",
  callScheduled: "success",
  dataShared: "warning",
  emergency: "danger",
  scheduled: "success",
  support: "warning",
  transferred: "default",
  unqualified: "danger",
};

export const customerStatuses = [
  { key: "callBack", label: "Call Back" },
  { key: "scheduled", label: "Scheduled" },
  { key: "support", label: "Support" },
  { key: "transferred", label: "Transferred" },
  { key: "unqualified", label: "Unqualified" },
];

export const defaultCustomerStatusOptions = [
  {
    label: "Call Back",
    value: "callBack",
  },
  {
    label: "Scheduled",
    value: "scheduled",
  },
  {
    label: "Support",
    value: "support",
  },
  {
    label: "Transferred",
    value: "transferred",
  },
  {
    label: "Unqualified",
    value: "unqualified",
  },
];

export const getCustomerStatusColor = (
  status?: CustomerStatus,
): ChipProps["color"] => (status ? customerStatusColorMap[status] : "default");
