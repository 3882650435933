export default function Message({
  speaker,
  text,
}: {
  speaker: string;
  text: string;
}) {
  const isAI = speaker === "AI";
  if (isAI) {
    return (
      <div className="bg-primary text-primary-foreground self-end ml-10 rounded-md p-2">
        {text}
      </div>
    );
  }
  return (
    <div>
      <p className="text-xs mb-1 ml-2">Customer</p>
      <div className="bg-default-200 text-default-foreground inline-flex mr-10 self-start rounded-md p-2">
        {text}
      </div>
    </div>
  );
}
