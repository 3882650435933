import { FC, FormEventHandler, memo, ReactNode } from "react";
import { SectionHeader } from "./SectionHeader";

export type FormContainerProps = {
  actions?: ReactNode;
  children: ReactNode;
  icon?: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  subTitle?: string;
  title?: string;
};

export const FormContainer: FC<FormContainerProps> = memo(
  ({ actions, children, icon, onSubmit, subTitle, title }) => (
    <form className="flex flex-col gap-6 w-full" onSubmit={onSubmit}>
      {title && (
        <SectionHeader icon={icon} subTitle={subTitle} title={title}>
          {actions}
        </SectionHeader>
      )}
      {children}
    </form>
  ),
);
